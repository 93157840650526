<template>
    <div>
        <v-card v-if="data && data.id" min-height="300" class="mt-4" @mouseover.stop="hover = true" @mouseleave.stop="hover = false" >
            <v-card-title class="d-flex flex-column">
                <div v-if="hover && rps[0] && rps[0].config && (!rps[0].config.hasOwnProperty('status') || rps[0].config.status != 'PROGRAMMED')" :id="'thumbHover_'+data.id" class="hoverThumb d-flex flex-column align-center justify-center">
                    <!-- <v-btn v-if="from === 'customLibrarySelect'" depressed color="pink" dark class="mb-4" @click="$emit('unselectModel', model)">{{ $t('unselect_model') }}</v-btn> -->
                    <v-btn small :color="'error'" dark :class="' mb-4 mr-2'" @click="$emit('removeModel', data.id)">{{ $t('removeCoach') }}</v-btn>
                    <v-btn @click="chooseCoach(data.id)" small color="primary" dark class="mb-4 mr-2">{{ $t('changeCoach') }}</v-btn>
                </div>
                <div class="headerCoach mb-2 mt-2">
                    <v-avatar size="100">
                        <v-img :src="api+'/user/'+data.id+'/getAvatar?token='+$store.state.auth.idToken+'&nocache=true'" ></v-img>
                    </v-avatar>
                    <div class="coachGlobalRating" v-if="data.coach_avis && data.coach_avis.length > 0" >
                        <div class="coachStarRating">
                            <b class="rating white--text">{{ globalNote }}</b>
                            <v-icon large color="#ffc107">$star_s</v-icon>
                        </div>
                    </div>
                    <v-btn v-if="rps[0] && rps[0].config && rps[0].config.status && rps[0].config.status == 'PROGRAMMED'" disabled depressed text class="square notified"><v-icon small color="success">$bell_on_d</v-icon></v-btn>
                </div>
                <span class=" coachName">{{ data.name +" "+data.firstname }}</span>
                <p class="mb-0" v-if="data.coach_detail && data.coach_detail.catch_phrase">"{{ data.coach_detail.catch_phrase }}"</p>
                <div v-if="data.coach_detail && data.coach_detail.skills && data.coach_detail.skills.length > 0" class="text-center mt-3">
                    <v-chip small v-for="(index) in 3" color="#efedff" v-show="data.coach_detail.skills[index - 1]" :key="'skill_'+index" class="mr-2 mb-2">{{ data.coach_detail.skills[index - 1] }}</v-chip>
                    <v-chip v-if="data.coach_detail.skills.length > 3" small color="#efedff">+{{ data.coach_detail.skills.length - 3 }}</v-chip>
                </div>
            </v-card-title>  
        </v-card>
        <v-card v-else class="mt-4" min-height="300">
            <v-card-title class="d-flex flex-column justify-space-between" style="min-height:300px;">
                <v-avatar size="100" color="#c9c8d8" class=" mt-2">
                    <v-icon class="iconNoCoach" color="white">$usertie_d</v-icon>
                </v-avatar>
                <span class=" coachName">{{ $t('coachtobedefined') }}</span>
                <v-btn depressed color="pink" class="white--text" @click="chooseCoach" style="width:100%;">{{ $t('chooseCoach') }}</v-btn>
            </v-card-title>
        </v-card>
        <LibraryDialogComponent v-if="formLib" :form="formLib" :coachNumber="coachNumber" :libType="libType" @cancel="formLib = null;" @selectModel="selectCoach" :showLibraryDialog="showLibraryDialog" @hideLibraryDialog="showLibraryDialog = false;" />
    </div>
</template>
<script>
import LibraryDialogComponent from "@/components/dialogs/LibraryDialogComponent";
import GenericDataService from '@/services/GenericDataService'
export default {
    name:"vCoachSelector",
    props: ["value", "loadData", "rps", "operationId", "coachNumber"],
    components:{LibraryDialogComponent},
    data() {
        return {
            data: {},
            api:process.env.VUE_APP_API_URL,
            formLib: null,
            libType: "COACH",
            showLibraryDialog: false,
            hover: false
        }
    },
    created() {
        this.initData();
    },
    mounted() {
        this.initData();
    },
    watch: {
        loadData: {
            immediate:true,
            handler(val){
                if(val){
                    this.initData();
                }
            }
        },
    },
    computed: {
        periodArray(){
            let array= [];
            this.rps.forEach(rp => {
                let obj = {
                    date_start: rp.config.date_start,
                    date_end: rp.config.date_end
                }
                array.push(obj);
            });
            return array
        },
        rpArray(){
            let array= [];
            this.rps.forEach(rp => {
                array.push(rp.config.place.value);
            });
            return array
        },
        globalNote:function(){
            if(this.data.coach_avis && this.data.coach_avis.length > 0){
                let total = 0;
                this.data.coach_avis.forEach((avis) => {
                    total += parseInt(avis.note)
                })
                let newTotal = total / this.data.coach_avis.length
                let test = ""+newTotal
                if(test.length > 3)
                    newTotal = newTotal.toFixed(1)
                else
                    newTotal = newTotal
                return newTotal
            } else {
                return 0
            }
        } 
    },
    methods: {
        initData(){
            if(this.value&& this.value.value){
                GenericDataService.getData('/user/get?id='+this.value.value+'&showCoachInfo=1').then((response) => {
                    this.data = response.data.data
                })
            }
        },
        chooseCoach(idToAdd){

            let userToexcludes = [];
            if(idToAdd && typeof(idToAdd) != "object")
                userToexcludes.push(idToAdd);


            this.rps.forEach(rp => {
                if(rp.config && rp.config.coach_selected && rp.config.coach_selected.length){
                    rp.config.coach_selected.forEach(element => {
                        if(element && element.value)
                            userToexcludes.push(element.value); 
                    });
                }
            });
            GenericDataService.getData("/library/getFilters?libType=" + this.libType+"&period="+JSON.stringify(this.periodArray)+"&place_id="+JSON.stringify(this.rpArray)+"&ope_id="+this.operationId+"&coachNumber="+this.coachNumber+"&excluded_ids="+JSON.stringify(userToexcludes)).then((response) => {
                this.formLib = response.data.data;
                this.showLibraryDialog = true;
            });
        },
        chooseCoachWithExclude(userId){
            GenericDataService.getData("/library/getFilters?libType=" + this.libType+"&period="+JSON.stringify(this.periodArray)+"&place_id="+JSON.stringify(this.rpArray)+"&ope_id="+this.operationId+"&exclude_id="+userId+"&coachNumber="+this.coachNumber).then((response) => {
                this.formLib = response.data.data;
                this.showLibraryDialog = true;
            });
        },
        selectCoach($evt){
            let objToSend = {
                text : $evt.firstname + " " + $evt.name,
                value : $evt.id,
                indispo: false
            }
            this.$emit('input', objToSend)
        }
    },
}
</script>
<style lang="scss">
    .headerCoach {
        position: relative;
        .coachGlobalRating {
            position:absolute;
            bottom:0;
            right:0;
            .coachStarRating {
                position:relative;
                .rating {
                    width:100% !important;
                    text-align: center;
                    position:absolute;
                    top:calc(50% + 2px);
                    left:50%;
                    z-index:15;
                    transform:translate(-50%, -50%);
                    font-size:12px;
                    font-weight:800;
                }
            }
        }
       
    }
    .notified {
        position:absolute;
        right:-40px;
    }
    .coachName {
            font-size:16px;
            font-weight:bolder
        }
        .iconNoCoach{
            width:50px !important;
        }
</style>